import { SvgIcon, SvgIconProps } from "@mui/material";

export const SephirotIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 50 50" fill="none" {...props}>
      <g clip-path="url(#clip0_201_2)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25 43C34.9411 43 43 34.9411 43 25C43 15.0589 34.9411 7 25 7C15.0589 7 7 15.0589 7 25C7 34.9411 15.0589 43 25 43ZM25 45C36.0457 45 45 36.0457 45 25C45 13.9543 36.0457 5 25 5C13.9543 5 5 13.9543 5 25C5 36.0457 13.9543 45 25 45Z"
          fill="currentColor"
        />
        <path
          d="M18.9688 29.9297L17.7578 37H12.2891L13.9297 28.9141C14.3984 26.6745 14.8281 25.0794 15.2188 24.1289C15.6094 23.1654 16.0911 22.4036 16.6641 21.8438C17.25 21.2708 18.0573 20.763 19.0859 20.3203L13.4414 12.7617H19.8477L27.7578 23.7188C28.5781 22.9766 29.2031 22.1042 29.6328 21.1016C30.0625 20.099 30.3685 18.7448 30.5508 17.0391L30.9609 12.7617H36.3516L35.8828 17.3906C35.7396 18.7057 35.4792 19.8711 35.1016 20.8867C34.8411 21.5768 34.5221 22.2279 34.1445 22.8398C33.7669 23.4388 33.2721 24.0768 32.6602 24.7539C32.0612 25.418 31.2669 26.1927 30.2773 27.0781L37.5625 37H31.1953L21.4492 23.4844C20.7591 23.888 20.2643 24.474 19.9648 25.2422C19.8216 25.6328 19.6654 26.2188 19.4961 27C19.3268 27.7812 19.151 28.7578 18.9688 29.9297Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_201_2">
          <rect width="50" height="50" fill="white"/>
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
