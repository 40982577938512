import { SetStateAction } from 'react';
import { atom } from 'jotai';
import { Chart } from '../../lib/types';
import { locationsAtom } from '../../core/location/state';
import { transliterateToHebrew } from '@danielpedroso/hebrew';

interface ChartState {
  isoDate: string;
  locationId: string;
  name: string;
  mode: 'edit' | 'view';
  doubleLettersEnd: 'kaf' | 'tav';
}

const initialState: ChartState = {
  isoDate: new Date().toISOString(),
  locationId: 'BNE',
  name: 'John',
  mode: 'view',
  doubleLettersEnd: 'kaf',
};
const initialStr = JSON.stringify(initialState);

const STORAGE_KEY = 'ASTRUM_SIGIL';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => {
    const str = get(strStateAtom);

    try {
      return JSON.parse(str)
    } catch {
      return JSON.parse(initialStr);
    }
  },
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const locationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  return locations.find(l => l.id === chartState.locationId ?? 'BNE') ?? locations[0];
});

export const chartAtom = atom<Chart | undefined>(undefined);

export const hebrewNameAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const name = chartState?.name ?? '';
  return transliterateToHebrew(name);
});
