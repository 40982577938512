import { SvgIcon, SvgIconProps } from "@mui/material";

export const SigilIcon: React.FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.5154 8.99543L19.0497 13.5342L6 17.0309L6.4848 10.3672L6 6L9.33305 12.7754M10.006 12.4444L8.66007 13.1065"
        stroke="currentColor"
        strokeLinejoin="bevel"
        fill="none"
      />
      <path
        d="M11.5155 9.29543C11.6811 9.29543 11.8155 9.16112 11.8155 8.99543C11.8155 8.82975 11.6811 8.69543 11.5155 8.69543C11.3498 8.69543 11.2155 8.82975 11.2155 8.99543C11.2155 9.16112 11.3498 9.29543 11.5155 9.29543Z"
        fill="white"
        stroke="currentColor"
      />
      <circle cx="12" cy="12" r="10.5" stroke="currentColor" fill="none" />
    </SvgIcon>
  )
};
