import { Provider } from 'jotai';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Routes, Route, BrowserRouter, Navigate, Outlet } from 'react-router-dom';
import 'moment-timezone';
import { Layout } from './core/layout';
import { createTheme, ThemeProvider } from '@mui/material';
import { SigilPage } from './pages/sigil';
import { SephirotPage } from './pages/sephirot';
import { BOVSPage } from './pages/bovs';

export const App: React.FC = () => {
  return (
    <ThemeProvider theme={createTheme()}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Provider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout><Outlet /></Layout>}>
                <Route path="/" element={<Navigate to="/sephirot" />} />
                <Route path="/sephirot" element={<SephirotPage />} />
                <Route path="/sigil" element={<SigilPage />} />
                <Route path="/bovs" element={<BOVSPage />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Provider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
