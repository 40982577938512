export const parshot = [
  ['Bereshit', 'Genesis 1:1-6:8', 'Isaiah 42:5-43:11'],
  ['Noach', 'Genesis 6:9-11:32', 'Isaiah 54:1-55:5'],
  ['Lech Lecha', 'Genesis 12:1–17:27', 'Isaiah 40:27-41:16'],
  ['Vayera', 'Genesis 18:1-22:24', 'II Kings 4:1-4:37'],
  ['Chayei Sarah', 'Genesis 23:1-25:18', 'I Kings 1:1-1:31'],
  ['Toldot', 'Genesis 25:19-28:9', 'Malachi 1:1-2:7'],
  ['Vayetze', 'Genesis 28:10-32:3', 'Hosea 12:13-14:10'],
  ['Vayishlach', 'Genesis 32:4-36:43', 'Hosea 11:7-12:12'],
  ['Vayeshev', 'Genesis 37:1–40:23', 'Amos 2:6-3:8'],
  ['Miketz', 'Genesis 41:1-44:17', 'I Kings 3:15-4:1'],
  ['Vayigash', 'Genesis 44:18-47:27', 'Ezekiel 37:15-37:28'],
  ['Vayechi', 'Genesis 47:28-50:26', 'I Kings 2:1-12'],
  ['Shemot', 'Exodus 1:1 - 6:1', 'Isaiah 27:6-28:13; 29:22-29:23'],
  ['Va\'era', 'Exodus 6:2-9:35', 'Ezekiel 28:25-29:21'],
  ['Bo', 'Exodus 10:1-13:16', 'Jeremiah 46:13-46:28'],
  ['Beshalach', 'Exodus 13:17-17:16', 'Judges 4:4-5:31'],
  ['Yitro', 'Exodus 18:1-20:23', 'Isaiah 6:1-7:6; 9:5-9:6'],
  ['Mishpatim', 'Exodus 21:1-24:18', 'Jeremiah 34:8-34:22; 33:25-33:26'],
  ['Terumah', 'Exodus 25:1-27:19', 'I Kings 5:26-6:13'],
  ['Tetzaveh', 'Exodus 27:20-30:10', 'Ezekiel 43:10-43:27'],
  ['Ki Tisa', 'Exodus 30:11-34:35', 'I Kings 18:1-18:39'],
  ['Vayakhel', 'Exodus 35:1-38:20', 'I Kings 7:40-7:50'],
  ['Pekudei', 'Exodus 38:21-40:38', 'I Kings 7:51-8:21'],
  ['Vayikra', 'Leviticus 1:1-5:26', 'Isaiah 43:21-44:23'],
  ['Tzav', 'Leviticus 6:1-8:36', 'Jeremiah 7:21-8:3; 9:22-9:23'],
  ['Shemini', 'Leviticus 9:1–11:47', 'II Samuel 6:1-7:17'],
  ['Tazria', 'Leviticus 12:1-13:59', 'II Kings 4:42-5:19'],
  ['Metzora', 'Leviticus 14:1–15:33', 'II Kings 7:3-7:20'],
  ['Acharei Mot', 'Leviticus 16:1–18:30', 'Ezekiel 22:1-22:19'],
  ['Kedoshim', 'Leviticus 19:1–20:27', 'Amos 9:7-9:15'],
  ['Emor', 'Leviticus 21:1–24:23', 'Ezekiel 44:15-44:31'],
  ['Behar', 'Leviticus 25:1-26:2', 'Jeremiah 32:6-32:27'],
  ['Bechukotai', 'Leviticus 26:3-27:34', 'Jeremiah 16:19-17:14; 33:25-33:26'],
  ['Bamidbar', 'Numbers 1:1–4:20', 'Hosea 2:1-2:22'],
  ['Naso', 'Numbers 4:21–7:89', 'Judges 13:2-13:25'],
  ['Behaalotecha', 'Numbers 8:1–12:16', 'Zechariah 2:14-4:7'],
  ['Shelach', 'Numbers 13:1–15:41', 'Joshua 2:1-2:24'],
  ['Korach', 'Numbers 16:1–18:32', 'I Samuel 11:14-12:22'],
  ['Chukat', 'Numbers 19:1–22:1', 'Judges 11:1-11:33'],
  ['Balak', 'Numbers 22:2–25:9', 'Micah 5:6-6:8'],
  ['Pinchas', 'Numbers 25:10–30:1', 'I Kings 18:46-19:21'],
  ['Matot', 'Numbers 30:2–32:42', 'Jeremiah 1:1-2:3'],
  ['Masei', 'Numbers 33:1–36:13', 'Jeremiah 2:4-28; 3:4'],
  ['Devarim', 'Deuteronomy 1:1–3:22', 'Isaiah 1:1-1:27'],
  ['Va\'etchanan', 'Deuteronomy 3:23–7:11', 'Isaiah 40:1-40:26'],
  ['Eikev', 'Deuteronomy 7:12–11:25', 'Isaiah 49:14-51:3'],
  ['Re\'eh', 'Deuteronomy 11:26–16:17', 'Isaiah 54:11-55:5'],
  ['Shoftim', 'Deuteronomy 16:18–21:9', 'Isaiah 51:12-52:12'],
  ['Ki Teitzei', 'Deuteronomy 21:10–25:19', 'Isaiah 54:1-54:10'],
  ['Ki Tavo', 'Deuteronomy 26:1-29:8', 'Isaiah 60:1-60:22'],
  ['Nitzavim', 'Deuteronomy 29:9-30:20', 'Isaiah 61:10-63:9'],
  ['Vayelech', 'Deuteronomy 31:1-30', 'Isaiah 55:6-56:8'],
  ['Haazinu', 'Deuteronomy 32:1-52', 'II Samuel 22:1-22:51'],
  ['V\'Zot HaBerachah', 'Deuteronomy 33:1-34:12', 'Joshua 1:1-1:18'],
];