import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { buildChart } from '@danielpedroso/astrum-calc';
import { SigilChart } from '@danielpedroso/chart/dist/sigil-chart';
import { Grid, MenuItem, TextField } from '@mui/material';
import { chartAtom, chartStateAtom, hebrewNameAtom, locationAtom } from './state';
import { useTranslation } from 'react-i18next';
import { SelectLocation } from '../../core/location/select';
import { DateTimeInput } from '../../core/datetime';
import { switchTimezones } from '../../core/datetime/utils';
import { Location as LocationType, locationsAtom } from '../../core/location/state';

export const SigilPage: React.FC = () => {
  const { t } = useTranslation();
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [chart, setChart] = useAtom(chartAtom);
  const [hebrewName] = useAtom(hebrewNameAtom);
  const [location] = useAtom(locationAtom);
  const [locations] = useAtom(locationsAtom);
  const [svgRef, setSvgRef] = useState<SVGSVGElement | null>(null);
  const [exportedImage, setExportedImage] = useState<string | null>(null);

  const handleSubtract = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).subtract(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleAdd = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).add(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleChangeDate = useCallback((d: string) => {
    if (!d) return;

    setChartState((s) => ({
      ...s,
      isoDate: d,
    }));
  }, [setChartState]);

  const handleChangeName = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      name: e?.target?.value ?? '',
    }));
  }, [setChartState]);

  const handleChangeMode = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      mode: e?.target?.value === 'edit' ? 'edit' : 'view',
    }));
  }, [setChartState]);

  const handleChangeDoubleLettersEnd = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setChartState((s) => ({
      ...s,
      doubleLettersEnd: e?.target?.value === 'kaf' ? 'kaf' : 'tav',
    }));
  }, [setChartState]);

  const handleLocationChanged = useCallback((locationId: string | undefined, loc?: LocationType) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const oldLocation = locations.find(l => l.id === s.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(s.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        locationId,
        isoDate: updated,
      };
    });
  }, [locations, setChartState]);

  useEffect(() => {
    if (!location) return;
    if (!chartState?.isoDate) return;

    buildChart({
      date: chartState.isoDate,
      lat: location.lat,
      lon: location.lon,
    }).then((chart) => {
      setChart(chart);
    });
  }, [chartState, location, setChart]);

  const handleExportButtonClick = useCallback(async () => {
    debugger;
    if (!svgRef) return;
    const svgText = svgRef.outerHTML;
    const blob = new Blob([svgText], {
      type: 'image/svg+xml;charset=utf-8',
    });

    const url = (window.URL || window.webkitURL).createObjectURL(blob);
    const canvas = document.createElement('canvas');
    canvas.width = 800;
    canvas.height = 800;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const png = await new Promise<string>((resolve) => {
      img.onload = () => {
        ctx?.drawImage(img, 0, 0);
        (window.URL || window.webkitURL).revokeObjectURL(url);
        resolve(canvas.toDataURL());
      }
      img.src = url;
    });
    setExportedImage(png);
  }, [svgRef]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5">{t('chartPage.dates.title')}</Typography>
            <Box display="flex" flexDirection="row">
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; h</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('day')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; d</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('month')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; m</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('year')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; y</Button>
              <DateTimeInput tz={location?.tz ?? moment.tz.guess()} value={chartState.isoDate} onChange={handleChangeDate} />
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('year')} sx={{ display: { xs: 'none', md: 'block' } }}>y &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('month')} sx={{ display: { xs: 'none', md: 'block' } }}>m &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('day')} sx={{ display: { xs: 'none', md: 'block' } }}>d &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>h &gt;</Button>
            </Box>
          </Box>
        </Paper>
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid item md={8} lg={9} pt={2} display="flex" flexDirection="column" maxWidth="100vw">
            <SigilChart chart={chart} name={hebrewName} mode={chartState.mode} svgRef={setSvgRef} doubleLettersEndInKaf={chartState.doubleLettersEnd === 'kaf'} />
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('chartPage.location.name')}</Typography>
                <TextField value={chartState.name} onChange={handleChangeName} />
                <Typography variant="h5">{t('chartPage.location.title')}</Typography>
                <SelectLocation label={t('chartPage.location.title')} value={chartState.locationId} onChange={handleLocationChanged} />
                <Typography variant="h5">{t('sigilPage.doubleLettersEnd.title')}</Typography>
                <TextField value={chartState.doubleLettersEnd} onChange={handleChangeDoubleLettersEnd} select>
                  <MenuItem key="kaf" value="kaf">{t('sigilPage.doubleLettersEnd.kaf')}</MenuItem>
                  <MenuItem key="tav" value="tav">{t('sigilPage.doubleLettersEnd.tav')}</MenuItem>
                </TextField>
                <Typography variant="h5">{t('sigilPage.mode.title')}</Typography>
                <TextField value={chartState.mode} onChange={handleChangeMode} select>
                  <MenuItem key="view" value="view">{t('sigilPage.mode.view')}</MenuItem>
                  <MenuItem key="edit" value="edit">{t('sigilPage.mode.edit')}</MenuItem>
                </TextField>
                <Button disabled={chartState.mode === 'edit'} onClick={handleExportButtonClick}>{t('sigilPage.export')}</Button>
              </Box>
              <Dialog open={!!exportedImage} onClose={() => setExportedImage(null)}>
                <DialogTitle>
                  <Typography variant="h5">{t('sigilPage.exportedDialog.title')}</Typography>
                  <Typography variant="subtitle1">{t('sigilPage.exportedDialog.subtitle')}</Typography>
                </DialogTitle>
                <DialogContent>
                  {exportedImage && <img alt="Sigil" src={exportedImage} style={{ maxWidth: '100%' }} />}
                </DialogContent>
              </Dialog>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
