import { useAtom } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { buildChart } from '@danielpedroso/astrum-calc';
import { Grid } from '@mui/material';
import { chartAtom, chartStateAtom, hewbrewDateAtom, locationAtom, zmanimAtom } from './state';
import { useTranslation } from 'react-i18next';
import { SelectLocation } from '../../core/location/select';
import { DateTimeInput } from '../../core/datetime';
import { switchTimezones } from '../../core/datetime/utils';
import { Location as LocationType, locationsAtom } from '../../core/location/state';
import { parshot } from './parshot';
import bg from './pages-bg.png';

interface Values {
  shabbatName: string;
  hebrewDate: string;
  torahPortion: string;
  haftarahReading: string;
  torahOldPage: string;
  torahNewPage: string;
  haftarahOldPage: string;
  haftarahNewPage: string;
  candleLighting: string;
  shabbatEnds: string;
}

const militaryTo12Hour = (military: string) => {
  const [h, m] = military.split(':');
  const hour = parseInt(h);
  const min = parseInt(m);

  const ampm = hour >= 12 ? 'pm' : 'am';
  const hour12 = hour % 12 || 12;
  const minStr = min.toString().padStart(2, '0');
  const hourStr = hour12.toString().padStart(2, '0');

  return `${hourStr}:${minStr}${ampm}`;
};

export const BOVSPage: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [chartState, setChartState] = useAtom(chartStateAtom);
  const [, setChart] = useAtom(chartAtom);
  const [hebrewDate] = useAtom(hewbrewDateAtom);
  const [zmanim] = useAtom(zmanimAtom);
  const [location] = useAtom(locationAtom);
  const [locations] = useAtom(locationsAtom);
  const [values, setValues] = useState<Values>({
    shabbatName: '',
    hebrewDate: '',
    torahPortion: '',
    haftarahReading: '',
    torahOldPage: '',
    torahNewPage: '',
    haftarahOldPage: '',
    haftarahNewPage: '',
    candleLighting: '',
    shabbatEnds: '',
  });
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawCanvas = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;
    const image = new Image();
    image.src = bg; // Your background image path

    image.onload = () => {
      // Draw the image onto the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      
      // Set font, style, etc.
      ctx.font = '64px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      // Draw text (adjust coordinates as needed)
      ctx.fillText(`Shabbat ${values.shabbatName} - ${values.hebrewDate}`, 72, 579);

      ctx.font = '48px Arial';
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      ctx.fillText(`Torah portion: ${values.torahPortion}`, 72, 679);
      ctx.fillText(`Haftarah reading: ${values.haftarahReading}`, 72, 739);

      ctx.font = '64px Arial';
      ctx.textAlign = 'right';
      ctx.fillText('Pages', 1856, 579);

      ctx.font = '48px Arial';
      ctx.fillText(`Old: ${values.torahOldPage}, New: ${values.torahNewPage}`, 1856, 679);
      ctx.fillText(`Old: ${values.haftarahOldPage}, New: ${values.haftarahNewPage}`, 1856, 739);
      
      // Shabbat times
      ctx.font = '32px Arial';
      ctx.textAlign = 'center';
      ctx.fillText(`Shabbat starts: Fri ${militaryTo12Hour(values.candleLighting)}, Shabbat ends: Sat ${militaryTo12Hour(values.shabbatEnds)}`, 960, 829);
    };
  }, [values]);;

  // Step 3: Use useEffect to React to Changes
  useEffect(() => {
    drawCanvas();
  }, [drawCanvas, values]); // Re-run the effect when 'values' changes

  const handleSubtract = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).subtract(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleAdd = useCallback((dur: moment.unitOfTime.DurationConstructor) => () => {
    setChartState((s) => ({
      ...s,
      isoDate: moment(s.isoDate).add(1, dur).toISOString(),
    }));
  }, [setChartState]);

  const handleChangeDate = useCallback((d: string) => {
    if (!d) return;

    setChartState((s) => ({
      ...s,
      isoDate: d,
    }));
  }, [setChartState]);

  const handleValuesChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    const { name, value } = e.target;

    setValues((v) => ({
      ...v,
      [name]: value,
    }));
  }, []);

  const handleLocationChanged = useCallback((locationId: string | undefined, loc?: LocationType) => {
    if (!locationId || !loc) return;

    setChartState((s) => {
      const oldLocation = locations.find(l => l.id === s.locationId);
      if (!oldLocation) return s;

      const updated = switchTimezones(s.isoDate, oldLocation.tz, loc.tz);

      return {
        ...s,
        locationId,
        isoDate: updated,
      };
    });
  }, [locations, setChartState]);

  useEffect(() => {
    if (!location) return;
    if (!chartState?.isoDate) return;

    (async () => {
      setLoading(true);
      try {
        await buildChart({
          date: chartState.isoDate,
          lat: location.lat,
          lon: location.lon,
          height: location.height,
          highPrecision: false,
        }).then((chart) => {
          setChart(chart);
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [chartState, location, setChart]);

  useEffect(() => {
    let ps = hebrewDate?.parsha?.num ?? [];
    if (!Array.isArray(ps)) {
      ps = [ps];
    }

    const torahPortion = ps.map((p) => {
      return p ? parshot[p - 1][1] : '';
    }).join('; ');
    const haftarah = ps.map((p) => {
      return p ? parshot[p - 1][2] : '';
    }).join('; ');


    setValues((v) => ({
      ...v,
      shabbatName: hebrewDate?.parsha?.render() ?? '',
      hebrewDate: `${hebrewDate?.date.getDate()} ${hebrewDate?.date.getMonthName()} ${hebrewDate?.date.getFullYear()}`,
      torahPortion,
      haftarahReading: haftarah,
      candleLighting: zmanim?.fri.candleLighting ?? '',
      shabbatEnds: zmanim?.sat.shabbatEnds ?? '',
    }));
  }, [hebrewDate, zmanim]);

  const handlePrint = useCallback(async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    var link = document.createElement('a');
    link.download = `Pages - ${values.shabbatName}.png`;
    link.href = canvas.toDataURL();
    link.click();
  }, [values.shabbatName]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Paper elevation={2} sx={{ p: 2 }}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h5">{t('chartPage.dates.title')}</Typography>
            <Box display="flex" flexDirection="row">
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; h</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('day')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; d</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('month')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; m</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleSubtract('year')} sx={{ display: { xs: 'none', md: 'block' } }}>&lt; y</Button>
              <DateTimeInput tz={location?.tz ?? moment.tz.guess()} value={chartState.isoDate} onChange={handleChangeDate} />
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('year')} sx={{ display: { xs: 'none', md: 'block' } }}>y &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('month')} sx={{ display: { xs: 'none', md: 'block' } }}>m &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('day')} sx={{ display: { xs: 'none', md: 'block' } }}>d &gt;</Button>
              <Button variant="outlined" size="small" color="primary" onClick={handleAdd('hour')} sx={{ display: { xs: 'none', md: 'block' } }}>h &gt;</Button>
            </Box>
          </Box>
        </Paper>
        <Grid container sx={{ flexDirection: { xs: 'column-reverse', md: 'row' }}} maxWidth="100vw">
          <Grid
            item
            md={8}
            lg={9}
            pt={2}
            display="flex"
            flexDirection="column"
            maxWidth="100vw"
            sx={{
              opacity: loading ? 0.5 : 1,
              position: 'relative',
            }}
          >
            {loading && <Typography variant="h3" width="100%" textAlign="center" sx={{ position: 'absolute', top: '300px' }}>{t('chartPage.loading')}</Typography>}
            <Box display="flex" flexDirection="column" gap={2} sx={{ mb: 2, padding: 2 }}>
              <Box display="flex" flexDirection="row" gap={2}>
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField label="Shabbat Name" name="shabbatName" value={values.shabbatName} onChange={handleValuesChanged} />
                  <TextField label="Hebrew Date" name="hebrewDate" value={values.hebrewDate} onChange={handleValuesChanged} />
                </Box>
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField label="Torah Portion" name="torahPortion" value={values.torahPortion} onChange={handleValuesChanged} />
                  <TextField label="Haftarah Reading" name="haftarahReading" value={values.haftarahReading} onChange={handleValuesChanged} />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" gap={2}>
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField label="Torah Old Page" name="torahOldPage" value={values.torahOldPage} onChange={handleValuesChanged} />
                  <TextField label="Torah New Page" name="torahNewPage" value={values.torahNewPage} onChange={handleValuesChanged} />
                </Box>
                <Box display="flex" flexDirection="row" gap={2}>
                  <TextField label="Haftarah Old Page" name="haftarahOldPage" value={values.haftarahOldPage} onChange={handleValuesChanged} />
                  <TextField label="Haftarah New Page" name="haftarahNewPage" value={values.haftarahNewPage} onChange={handleValuesChanged} />
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" gap={2}>
                <TextField label="Candle Lighting" name="candleLighting" value={values.candleLighting} onChange={handleValuesChanged} />
                <TextField label="Shabbat Ends" name="shabbatEnds" value={values.shabbatEnds} onChange={handleValuesChanged} />
              </Box>

              <canvas ref={canvasRef} id="canvas" width="1928" height="1080">
              </canvas>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} maxWidth="100vw">
            <Paper elevation={2} sx={{ p: 2, mb: 2 }}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography variant="h5">{t('chartPage.location.title')}</Typography>
                <SelectLocation label={t('chartPage.location.title')} value={chartState.locationId} onChange={handleLocationChanged} />
                <Typography variant="body2">Service date: {hebrewDate?.date.greg().toLocaleDateString()}</Typography>
                <Button variant="contained" color="primary" onClick={handlePrint}>Download</Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
