import { SetStateAction } from 'react';
import { atom } from 'jotai';
import { Chart } from '../../lib/types';
import { locationsAtom } from '../../core/location/state';
import { PathType } from '@danielpedroso/chart/dist/utils';
import { getZmanim, Zmanim } from '@danielpedroso/astrum-calc/dist/judaism';
import { HebrewCalendar, Event, HDate, ParshaEvent } from '@hebcal/core';
import moment from 'moment-timezone';

interface ChartState {
  isoDate: string;
  locationId: string;
  treeType: PathType;
}

const initialState: ChartState = {
  isoDate: new Date().toISOString(),
  locationId: 'BNE',
  treeType: 'lurianic',
};
const initialStr = JSON.stringify(initialState);

const STORAGE_KEY = 'ASTRUM_BVOS_CALC';

const strStateAtom = atom(localStorage.getItem(STORAGE_KEY) ?? initialStr);

export const chartStateAtom = atom(
  (get) => {
    const str = get(strStateAtom);

    try {
      return JSON.parse(str)
    } catch {
      return JSON.parse(initialStr);
    }
  },
  (get, set, newState: SetStateAction<ChartState>) => {
    let newV: ChartState;
    if (typeof newState === 'function') {
      newV = newState(get(chartStateAtom));
    } else {
      newV = newState;
    }

    const newVal = JSON.stringify(newV ?? initialState);
    set(strStateAtom, newVal);
    localStorage.setItem(STORAGE_KEY, newVal);
  }
);

export const locationAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  const locations = get(locationsAtom);
  return locations.find(l => l.id === chartState.locationId ?? 'BNE') ?? locations[0];
});

export const dateAtom = atom((get) => {
  const chartState = get(chartStateAtom);
  let fri = moment(chartState.isoDate).tz(get(locationAtom).tz).set({ hour: 6, minute: 0, second: 0, millisecond: 0 });

  // make sure dt is a Friday. If it's past friday, go back to last friday. If it's before friday, to forward to next friday.
  if (fri.day() !== 5) {
    if (fri.day() > 5) {
      const diff = fri.day() - 5;
      fri = fri.subtract(diff, 'days');
    } else {
      const diff = 5 - fri.day();
      fri = fri.add(diff, 'days');
    }
  }

  let sat = fri.clone().add(1, 'day');

  return { fri, sat };
});

export const zmanimAtom = atom<{ fri: Zmanim, sat: Zmanim } | undefined>(
  (get) => {
    const location = get(locationAtom);
    const dates = get(dateAtom);
    if (!location) return undefined;

    const fri = getZmanim(moment.tz(dates.fri, location.tz), location.lat, location.lon, location.height);
    const sat = getZmanim(moment.tz(dates.sat, location.tz), location.lat, location.lon, location.height);
    return { fri, sat };
  },
);

export const hewbrewDateAtom = atom<{ date: HDate, parsha: ParshaEvent } | undefined>(
  (get) => {
    const dts = get(dateAtom);

    const cal = HebrewCalendar.calendar({
      addHebrewDates: true,
      start: dts.sat.set('hour', 0).toDate(),
      end: dts.sat.set('hour', 23).toDate(),
      sedrot: true,
    });

    const date = cal[0].date;
    const parsha = cal.find((e: Event) => e instanceof ParshaEvent) as ParshaEvent;

    return { date, parsha };
  },
);

export const chartAtom = atom<Chart | undefined>(undefined);
